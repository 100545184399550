import Link from "next/link";

interface HeroProps {
    total?: number;
    companiesTotal?: number;
    utisciTotal?: number;
}

export default function Hero({
    total,
    companiesTotal,
    utisciTotal,
}: HeroProps) {
    return (
        <section className="bg-gray-light">
            <div className="pt-10 pb-16 md:py-28 text-center max-w-screen-sm m-auto px-6 lg:px-0">
                <h1
                    className={`md:text-4xl md:leading-snug pb-4 font-semibold`}
                >
                    Najveća baza IT poslova i firmi u Bosni i Hercegovini
                </h1>
                {total ? (
                    <p className="font-normal text-gray-dark">
                        Trenutno aktivno{" "}
                        <Link href="/poslovi/1">
                            <a className="text-blue underline font-semibold">
                                {total}
                            </a>
                        </Link>{" "}
                        poslova i{" "}
                        <Link href="/recenzije">
                            <a className="text-blue underline font-semibold">
                                {utisciTotal}
                            </a>
                        </Link>{" "}
                        recenzija u okviru{" "}
                        <Link href="/kompanije">
                            <a className="text-blue underline font-semibold">
                                {companiesTotal}
                            </a>
                        </Link>{" "}
                        kompanija. Poslovi za developere, dizajnere, QA,
                        marketing i menadžment koji se dnevno dodaju i
                        osvježavaju.
                    </p>
                ) : (
                    <p className="font-normal text-gray-dark">
                        Poslovi za IT ekipu: Developere, dizajnere, QA,
                        marketing i menadžment koji se dnevno dodaju i
                        osvježavaju.
                    </p>
                )}
            </div>
        </section>
    );
}
