import { data } from "msw/lib/types/context";
import { jobsPerPage, revalidate } from "../common/constants";
import { JobListing } from "../common/types";
import Home from "../view/screens/Home/Home";
import { CompaniesListing, handleCompanyListing } from "./api/company";
import {
    getJobsPerDepartment,
    getAllDevelopmentJobs,
    getAllPremiumJobs,
} from "./api/job/get";
import { handleGetUtisciTotal } from "./api/utisak/count";

interface PocetnaProps {
    allPremiumJobs: JobListing[];
    allJobs: JobListing[];
    frontEndJobs: JobListing[];
    backEndJobs: JobListing[];
    fullStackJobs: JobListing[];
    designJobs: JobListing[];
    qaJobs: JobListing[];
    managementJobs: JobListing[];
    marketingJobs: JobListing[];
    allJobsCount: number;
    frontEndCount: number;
    backEndCount: number;
    fullStackCount: number;
    qaCount: number;
    designCount: number;
    managementCount: number;
    marketingCount: number;
    utisciTotal: number;
    companies: { data: CompaniesListing[]; total: number };
}

export default function Pocetna({
    allPremiumJobs,
    allJobs,
    frontEndJobs,
    backEndJobs,
    fullStackJobs,
    designJobs,
    qaJobs,
    managementJobs,
    marketingJobs,
    qaCount,
    allJobsCount,
    frontEndCount,
    backEndCount,
    fullStackCount,
    designCount,
    managementCount,
    marketingCount,
    companies,
    utisciTotal,
}: PocetnaProps) {
    return (
        <Home
            allPremiumJobs={allPremiumJobs}
            allJobs={allJobs}
            allJobsCount={allJobsCount}
            frontEndJobs={frontEndJobs}
            backEndJobs={backEndJobs}
            fullStackJobs={fullStackJobs}
            designJobs={designJobs}
            qaJobs={qaJobs}
            managementJobs={managementJobs}
            marketingJobs={marketingJobs}
            frontEndCount={frontEndCount}
            backEndCount={backEndCount}
            fullStackCount={fullStackCount}
            qaCount={qaCount}
            designCount={designCount}
            managementCount={managementCount}
            marketingCount={marketingCount}
            companies={companies.data}
            companiesTotal={companies.total}
            utisciTotal={utisciTotal}
        />
    );
}

export async function getStaticProps() {
    const allPremiumJobs = await getAllPremiumJobs({
        perPage: 99,
        page: 1,
    });

    const allJobs = await getAllDevelopmentJobs({
        perPage: jobsPerPage,
        page: 1,
    });

    const frontEndJobs = await getJobsPerDepartment({
        department: "frontend",
        perPage: 8,
        page: 1,
    });

    const backEndJobs = await getJobsPerDepartment({
        department: "backend",
        perPage: 8,
        page: 1,
    });

    const fullStackJobs = await getJobsPerDepartment({
        department: "full-stack",
        perPage: 8,
        page: 1,
    });

    const designJobs = await getJobsPerDepartment({
        department: "design",
        perPage: 8,
        page: 1,
    });

    const qaJobs = await getJobsPerDepartment({
        department: "qa",
        perPage: 8,
        page: 1,
    });

    const managementJobs = await getJobsPerDepartment({
        department: "management",
        perPage: 8,
        page: 1,
    });

    const marketingJobs = await getJobsPerDepartment({
        department: "marketing",
        perPage: 8,
        page: 1,
    });

    const companies = await handleCompanyListing({
        perPage: jobsPerPage,
        page: 1,
    });

    const utisciTotal = await handleGetUtisciTotal();

    if (frontEndJobs.data) {
        return {
            props: {
                allPremiumJobs: allPremiumJobs.data,
                allJobs: allJobs.data,
                allJobsCount: allJobs.count,
                frontEndJobs: frontEndJobs.data,
                backEndJobs: backEndJobs.data,
                fullStackJobs: fullStackJobs.data,
                designJobs: designJobs.data,
                qaJobs: qaJobs.data,
                managementJobs: managementJobs.data,
                marketingJobs: marketingJobs.data,
                frontEndCount: frontEndJobs.count,
                backEndCount: backEndJobs.count,
                fullStackCount: fullStackJobs.count,
                designCount: designJobs.count,
                qaCount: qaJobs.count,
                managementCount: managementJobs.count,
                marketingCount: marketingJobs.count,
                companies,
                utisciTotal: utisciTotal?.total,
            },
            revalidate: revalidate,
        };
    }

    if (!allJobs.data) {
        return {
            notFound: true,
        };
    }
}
