import Link from "next/link";
import { UsersIcon } from "@heroicons/react/outline";
import { CompaniesListing } from "../../../../../pages/api/company";
import LocationIcon from "../../../../components/Icons/LocationIcon";

const parseWord = {
    0: "Bez otvorenih pozicija",
    1: "1 Otvorena pozicija",
    2: "2 Otvorene pozicija",
    3: "3 Otvorene pozicije",
    4: "4 Otvorene pozicije",
};

export default function CompanyBox(props: { company: CompaniesListing }) {
    const { company } = props;

    return (
        <div
            className={`job-shadow border p-6 ${
                company.plan === "PREMIUM"
                    ? "bg-premium-100 border-premium-200  hover:border-premium-400"
                    : "bg-white border-gray hover:border-blue"
            }  transition-all duration-300 rounded-lg w-full flex flex-col justify-between transform hover:-translate-y-1 overflow-hidden`}
        >
            <div>
                <Link href={`/kompanija/${company.slug}`}>
                    <a>
                        <div className="w-12">
                            <img
                                src={company.logo}
                                alt={company.name}
                                className="rounded-full"
                            />
                        </div>
                        <h2 className="font-semibold text-xl mt-2 mb-2">
                            {company.name}
                        </h2>
                        {company.plan === "PREMIUM" && (
                            <span className="absolute right-0 top-0 text-xs bg-premium-300 text-white px-2 py-1">
                                premium
                            </span>
                        )}
                    </a>
                </Link>
                <span className="text-gray-dark text-sm flex">
                    <LocationIcon />
                    {company.cities}
                </span>
                <span className="text-gray-dark text-sm mt-2 flex">
                    <UsersIcon className="h-4 w-4 mr-1" aria-hidden="true" />
                    {company.openPositions < 5
                        ? parseWord[company.openPositions]
                        : `${company.openPositions} otvorenih pozicija`}
                </span>
                <p className="mt-2 leading-7">{company.excerpt}</p>
            </div>
            <div className="flex justify-between mt-4">
                <Link href={`/kompanija/${company.slug}/otvorene-pozicije`}>
                    <a>
                        <button className="btn-primary text-xs rounded-lg">
                            Otvorene pozicije
                        </button>
                    </a>
                </Link>
                <Link href={`/kompanija/${company.slug}/iskustva`}>
                    <a>
                        <button className="btn-secondary text-xs rounded-lg">
                            Iskustva
                            {company.utisci > 0 && ` (${company.utisci})`}
                        </button>
                    </a>
                </Link>
            </div>
        </div>
    );
}
