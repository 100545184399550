export default function Tabs({ activeTab, setActiveTab }) {
    const getActiveTab = (active) => {
        if (active === activeTab) return "text-blue text-500 underline";
        return "";
    };
    return (
        <ul className="list-none flex space-x-4 mb-4 md:-mt-12 md:justify-end">
            <li className={`font-medium ${getActiveTab("all")} cursor-pointer`} onClick={() => setActiveTab("all")}>
                Sve
            </li>
            <li className={`font-medium ${getActiveTab("front-end")} cursor-pointer`} onClick={() => setActiveTab("front-end")}>
                Front-End
            </li>
            <li className={`font-medium ${getActiveTab("back-end")} cursor-pointer`} onClick={() => setActiveTab("back-end")}>
                Back-End
            </li>
            <li className={`font-medium ${getActiveTab("full-stack")} cursor-pointer`} onClick={() => setActiveTab("full-stack")}>
                Full Stack
            </li>
            <li className={`font-medium ${getActiveTab("qa")} cursor-pointer`} onClick={() => setActiveTab("qa")}>
                QA
            </li>
        </ul>
    );
}
