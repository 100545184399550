import Header from "../../components/Header/Header";
import SearchBox from "../../components/SearchBox/SearchBox";
import ListItem from "../../components/ListItem/ListItem";
import Footer from "../../components/Footer/Footer";
import Newsletter from "../../components/Footer/components/Newsletter/Newsletter";
import { JobListing } from "../../../common/types";
import CompanyBox from "../Kompanije/components/CompanyBox/CompanyBox";
import { CompaniesListing } from "../../../pages/api/company";
import Hero from "./components/Hero";
import Link from "next/link";
import { NextSeo } from "next-seo";
import { useState } from "react";
import Tabs from "./components/Tabs/Tabs";
interface HomeProps {
    companies: CompaniesListing[];
    allJobs: JobListing[];
    allPremiumJobs: JobListing[];
    frontEndJobs: JobListing[];
    backEndJobs: JobListing[];
    fullStackJobs: JobListing[];
    designJobs: JobListing[];
    qaJobs: JobListing[];
    managementJobs: JobListing[];
    marketingJobs: JobListing[];
    allJobsCount: number;
    frontEndCount: number;
    backEndCount: number;
    fullStackCount: number;
    qaCount: number;
    designCount: number;
    managementCount: number;
    marketingCount: number;
    utisciTotal: number;
    companiesTotal: number;
    type?: string;
    slug?: string;
    name?: string;
}

export default function Home({
    allPremiumJobs,
    companies,
    allJobs,
    frontEndJobs,
    backEndJobs,
    fullStackJobs,
    designJobs,
    qaJobs,
    managementJobs,
    marketingJobs,
    allJobsCount,
    frontEndCount,
    backEndCount,
    fullStackCount,
    qaCount,
    designCount,
    managementCount,
    marketingCount,
    type,
    slug,
    companiesTotal,
    utisciTotal,
    name,
}: HomeProps) {
    const [activeTab, setActiveTab] = useState("all");
    const total =
        frontEndCount +
        backEndCount +
        fullStackCount +
        qaCount +
        designCount +
        managementCount +
        marketingCount;
    return (
        <div className="relative bg-white">
            <Header />
            <NextSeo
                title="Dzobs.com | IT poslovi i iskustva | Najveća baza u BiH"
                description="Poslovi za programere, QA, dizajnere, menadžere i marketing. Sarajevo, Banja Luka, Mostar, Tuzla, Zenica, Doboj, Bijeljina."
                canonical="https://www.dzobs.com/"
                openGraph={{
                    images: [
                        {
                            url: "https://www.dzobs.com/social.png",
                        },
                    ],
                }}
            />
            <Hero
                total={total}
                companiesTotal={companiesTotal}
                utisciTotal={utisciTotal}
            />
            <SearchBox />
            <div className="relative mt-12 mb-32 max-w-screen-lg mx-auto px-6 lg:px-0">
                {allPremiumJobs.length > 0 && (
                    <div className="mb-14">
                        <h2 className="mb-6 mt-12 font-medium text-3xl">
                            Izdvojeni Oglasi
                        </h2>
                        {allPremiumJobs
                            ? allPremiumJobs.map((job) => (
                                  <ListItem key={job.id} job={job} />
                              ))
                            : "Loading..."}
                    </div>
                )}

                {activeTab === "all" && (
                    <>
                        <h2 className="my-6 font-medium text-3xl">
                            Development
                        </h2>
                        <Tabs
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                        />
                        {allJobs
                            ? allJobs.map((job) => (
                                  <ListItem key={job.id} job={job} />
                              ))
                            : "Loading..."}
                        {allJobs.length === 0 && (
                            <div className="text-center">
                                Huh... izgleda da nemamo rezultata za tu
                                pretragu. <br />
                                Možda je vrijeme da baciš oko na drugu
                                tehnologiju? ili čak grad :o
                            </div>
                        )}
                        <div className="flex items-center justify-center pt-6">
                            <Link href="/poslovi/1">
                                <button className="btn-primary w-60 py-4">
                                    Svi poslovi ({allJobsCount})
                                </button>
                            </Link>
                        </div>
                    </>
                )}
                {activeTab === "back-end" && (
                    <>
                        <h2 className="my-6 font-medium text-3xl">
                            Back-End Poslovi
                        </h2>
                        <Tabs
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                        />
                        {backEndJobs
                            ? backEndJobs.map((job) => (
                                  <ListItem key={job.id} job={job} />
                              ))
                            : "Loading..."}
                        {backEndJobs.length === 0 && (
                            <div className="text-center">
                                Huh... izgleda da nemamo rezultata za tu
                                pretragu. <br />
                                Možda je vrijeme da baciš oko na drugu
                                tehnologiju? ili čak grad :o
                            </div>
                        )}
                        <div className="flex items-center justify-center pt-6">
                            <Link href="/department/backend">
                                <button className="btn-primary w-60 py-4">
                                    Back-End poslovi ({backEndCount})
                                </button>
                            </Link>
                        </div>{" "}
                    </>
                )}
                {activeTab === "front-end" && (
                    <>
                        <h2 className="my-6 font-medium text-3xl">
                            Front-End i Mobile Poslovi
                        </h2>
                        <Tabs
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                        />
                        {frontEndJobs
                            ? frontEndJobs.map((job) => (
                                  <ListItem key={job.id} job={job} />
                              ))
                            : "Loading..."}
                        {frontEndJobs.length === 0 && (
                            <div className="text-center">
                                Huh... izgleda da nemamo rezultata za tu
                                pretragu. <br />
                                Možda je vrijeme da baciš oko na drugu
                                tehnologiju? ili čak grad :o
                            </div>
                        )}
                        <div className="flex items-center justify-center pt-6">
                            <Link href="/department/frontend">
                                <button className="btn-primary w-60 py-4">
                                    Front-End poslovi ({frontEndCount})
                                </button>
                            </Link>
                        </div>{" "}
                    </>
                )}
                {activeTab === "full-stack" && (
                    <>
                        <h2 className="my-6 font-medium text-3xl">
                            Full-Stack Poslovi
                        </h2>
                        <Tabs
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                        />
                        {fullStackJobs
                            ? fullStackJobs.map((job) => (
                                  <ListItem key={job.id} job={job} />
                              ))
                            : "Loading..."}
                        {fullStackJobs.length === 0 && (
                            <div className="text-center">
                                Huh... izgleda da nemamo rezultata za tu
                                pretragu. <br />
                                Možda je vrijeme da baciš oko na drugu
                                tehnologiju? ili čak grad :o
                            </div>
                        )}{" "}
                        <div className="flex items-center justify-center pt-6">
                            <Link href="/department/full-stack">
                                <button className="btn-primary w-60 py-4">
                                    Full-Stack poslovi ({fullStackCount})
                                </button>
                            </Link>
                        </div>
                    </>
                )}
                {activeTab === "qa" && (
                    <>
                        <h2 className="mb-6 mt-12 font-medium text-3xl">QA</h2>
                        <Tabs
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                        />
                        {qaJobs
                            ? qaJobs.map((job) => (
                                  <ListItem key={job.id} job={job} />
                              ))
                            : "Loading..."}
                        {qaJobs.length === 0 && (
                            <div className="text-center">
                                Huh... izgleda da nemamo rezultata za tu
                                pretragu. <br />
                                Možda je vrijeme da baciš oko na drugu
                                tehnologiju? ili čak grad :o
                            </div>
                        )}
                        <div className="flex items-center justify-center pt-6">
                            <Link href="/department/qa">
                                <button className="btn-primary w-60 py-4">
                                    QA poslovi ({qaCount})
                                </button>
                            </Link>
                        </div>
                    </>
                )}
                <h2 className="mb-6 mt-12 font-medium text-3xl">Management</h2>
                {managementJobs
                    ? managementJobs.map((job) => (
                          <ListItem key={job.id} job={job} />
                      ))
                    : "Loading..."}
                {managementJobs.length === 0 && (
                    <div className="text-center">
                        Huh... izgleda da nemamo rezultata za tu pretragu.{" "}
                        <br />
                        Možda je vrijeme da baciš oko na drugu tehnologiju? ili
                        čak grad :o
                    </div>
                )}
                <div className="flex items-center justify-center pt-6">
                    <Link href="/department/management">
                        <button className="btn-primary w-60 py-4">
                            Management poslovi ({managementCount})
                        </button>
                    </Link>
                </div>

                <h2 className="mb-6 mt-12 font-medium text-3xl">Dizajn</h2>
                {designJobs
                    ? designJobs.map((job) => (
                          <ListItem key={job.id} job={job} />
                      ))
                    : "Loading..."}
                {designJobs.length === 0 && (
                    <div className="text-center">
                        Huh... izgleda da nemamo rezultata za tu pretragu.{" "}
                        <br />
                        Možda je vrijeme da baciš oko na drugu tehnologiju? ili
                        čak grad :o
                    </div>
                )}
                <div className="flex items-center justify-center pt-6">
                    <Link href="/department/design">
                        <button className="btn-primary w-60 py-4">
                            Dizajn poslovi ({designCount})
                        </button>
                    </Link>
                </div>
                <h2 className="mb-6 mt-12 font-medium text-3xl">Marketing</h2>
                {marketingJobs
                    ? marketingJobs.map((job) => (
                          <ListItem key={job.id} job={job} />
                      ))
                    : "Loading..."}
                {marketingJobs.length === 0 && (
                    <div className="text-center">
                        Huh... izgleda da nemamo rezultata za tu pretragu.{" "}
                        <br />
                        Možda je vrijeme da baciš oko na drugu tehnologiju? ili
                        čak grad :o
                    </div>
                )}
                <div className="flex items-center justify-center pt-6">
                    <Link href="/department/marketing">
                        <button className="btn-primary w-60 py-4">
                            Marketing poslovi ({marketingCount})
                        </button>
                    </Link>
                </div>
            </div>
            <section className="bg-gray-light">
                <div className="pt-10 pb-28 md:pt-18 md:pb-36 text-center max-w-screen-sm m-auto px-6 lg:px-0">
                    <h1
                        className={`md:text-4xl md:leading-snug pb-4 font-semibold ${
                            type && "capitalize"
                        }`}
                    >
                        Istraži kompanije
                    </h1>
                    <p className="font-normal text-gray-dark">
                        Pogledaj koje kompanije zapošljavaju, pročitaj iskustva
                        drugih o kompanijama i ostavi svoja iskustva.
                    </p>
                </div>
            </section>
            <div className="pt-9 pb-9">
                <div className="relative -mt-24 max-w-screen-lg mx-auto px-6 lg:px-0 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {companies.length > 0 &&
                        companies.map((company) => (
                            <CompanyBox key={company.slug} company={company} />
                        ))}
                </div>
                <div className="flex items-center justify-center pt-6 mb-12 mt-12">
                    <Link href="/kompanije">
                        <button className="btn-primary w-60 py-4">
                            Sve kompanije
                        </button>
                    </Link>
                </div>
            </div>
            <Newsletter bg="bg-gray-light" />
            <Footer />
        </div>
    );
}
